/* import React, { Component, PropTypes } from 'react';*/
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
/* import IconButton from '@material-ui/core/IconButton'; */
/* import SearchIcon from '@material-ui/icons/Search'; */
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import Markdown from './Markdown';
import post1 from './blog-post.1.js';
import post2 from './blog-post.2.js';
import post3 from './blog-post.3.js'; 
import post4 from './blog-post.4.js';
/* import GoogleAd from './google-ad.js'; */
/* import Img from 'react-image' */


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '} 2018-{new Date().getFullYear()}{'   '}
      <Link color="inherit" href="https://www.oxy-gen.mobi/">
        Python Snippets 
      </Link>{'   '}
      {'.'}
    </Typography>
  );
}

function handleSearch(event) {
   // console.log(event.type);
   // this.setState({value: event.target.value});
   //console.log(event.target.value);
   document.getElementById("searchstatus").innerHTML = event.target.value;
  if (event.target.value.length > 3 || event.type === "submit") {
  document.getElementById("searchstatus").innerHTML = "Performing lookup...";
  var xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function() {
    if (this.readyState === 4 && this.status === 200) {
     var myArr = JSON.parse(this.responseText);
      //console.log(myArr);
     /* var tabheader = document.getElementById("tabheader");*/
     // EXTRACT VALUE FOR HTML HEADER. 
        var col = [];
        // skip column i=0
        for (var i = 1; i < myArr.length; i++) {
            for (var key in myArr[i]) {
                if (col.indexOf(key) === -1) {
                    col.push(key);
                }
            }
        }

        var table = document.createElement("table");
        // CREATE HTML TABLE HEADER ROW 
        var tr = table.insertRow(-1);                   // TABLE ROW.
        // skip column l=0
        for (var l = 1; l < col.length; l++) {
            var th = document.createElement("th");      // TABLE HEADER.
            th.innerHTML = col[l];
            tr.appendChild(th);
        }

        // ADD JSON DATA TO THE TABLE AS ROWS.
        // skip column 0 k=0 j=0
        for (var k = 1; k < myArr.length; k++) {
            tr = table.insertRow(-1);
            for (var j = 1; j < col.length; j++) {
                var tabCell = tr.insertCell(-1);
                //tabCell.innerHTML = myArr[k][col[j]].replace("_CRLF_","<br>").replace("_WS_"," ").replace("_TAB_","    ");
                tabCell.innerHTML = decodeURIComponent(myArr[k][col[j]].replaceAll("_WS_"," "))
            }
        }

        // ADD THE NEWLY CREATED TABLE WITH JSON DATA TO A CONTAINER.
        var divContainer = document.getElementById("result_table");
//console.log(myArr.length);
        if ( myArr.length > 0 ) {
           divContainer.innerHTML = "";
           divContainer.appendChild(table);
        } else {
           divContainer.innerHTML = "no match";
        }
        document.getElementById("searchstatus").innerHTML = "";
        document.getElementById("hello1").innerHTML = "";
        document.getElementById("hello2").innerHTML = "";
        document.getElementById("hello3").innerHTML = "";
      }
    };
   xhttp.open("GET", "https://oxy-gen.mobi/api/snippets?query="+event.target.value, true);
   xhttp.send(); 
   } 
  }


class SearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: ''};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
    handleSearch(event);
  }

  handleSubmit(event) {
    /* alert('A name was submitted: ' + this.state.value);*/
    //console.log(this.state.value);
    event.preventDefault();
    event.target.value=this.state.value;
    handleSearch(event);
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>        <label>
          <input type="text" placeholder="Start typing your search here.." id="query" onKeyUp={this.loadXMLDoc} value={this.state.value} onChange={this.handleChange} />        </label>
        <input type="submit" value="Search" />
      </form>
    );
  }
}

const useStyles = makeStyles(theme => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage: 'url(./blue.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  mainFeaturedPostContent: {
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
  },
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 160,
  },
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
  sidebarAboutBox: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
  },
  sidebarSection: {
    marginTop: theme.spacing(3),
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(8),
    padding: theme.spacing(6, 0),
  },
}));

const sections = [
  /* 'Blog',
  'Archive',
  'Search', */
];

const featuredPosts = [ 
 {
    title: 'hello_world.py',
    description:
      'print ("Hello world")'
  }
 /* ,
 {
    title: 'Using Python Pandas',
    date: 'Mar 11 2019',
    description:
      'Using Spark clusters to make sense of massive amounts of data we often use pandas.....'
  },  */
];

const posts = [ post1, post2, post3, post4 ];

const areas = [
  'Pandas',
  'Analytics',
  'Crypto',
  'Lambda',
  'Error handling',
  'File I/O',
  'Technical Documentation',
  'File transfer',
  'API Development',
  'Database',
  'Boto3'
];

const social = ['GitHub', 'Twitter', /* 'Facebook' */ ];

export default function Blog() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg">
         <Toolbar className={classes.toolbar}>
           <Typography
            component="h2"
            variant="h5"
            color="inherit"
            align="center"
            noWrap
            className={classes.toolbarTitle}
          >  
            Python Snippets 
          </Typography>   
          {/* <IconButton>
             <SearchIcon /> 
          </IconButton> */}
          <Button variant="outlined" href="https://www.oxy-gen.mobi/services.html" size="small">
            Oxy-gen.mobi
          </Button> 
        </Toolbar>
        <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
          {sections.map(section => (
            <Link
              color="inherit"
              noWrap
              key={section}
              variant="body2"
              href="#"
              className={classes.toolbarLink}
            >
              {section}
            </Link>
          ))}
        </Toolbar>
        <main>
          {/* Main featured post */}
          <Paper className={classes.mainFeaturedPost}>
            {/* Increase the priority of the hero background image */}
            {
              <img
                style={{ display: 'none' }}
                src="blue.jpg"
                alt="background"
              />
            }
	  <div className={classes.overlay} />
            <Grid container>
              <Grid item md={6}>
                <div className={classes.mainFeaturedPostContent}>
                  <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                  Python Snippets
                  </Typography>
                  <Typography variant="h5" color="inherit" paragraph>
                  </Typography>
                  <Link variant="subtitle1" href="#">
                  .
                  </Link>
                </div>
              </Grid>
            </Grid>
          </Paper>
          {/* End main featured post */}
<h1>Search Python Snippets</h1>
<SearchForm />
<br></br>

          {/* Sub featured posts */}
          <Grid container spacing={4}>
            {featuredPosts.map(post => (
              <Grid item key={post.title} xs={12} md={12}>
               {/* <CardActionArea component="a" href="#"> */}
                  <Card className={classes.card}>
                    <div className={classes.cardDetails}>
                      <CardContent>
                  <div id="searchstatus" ></div>
                  <div id="tabheader" ></div>
                  <div id="result_table" ></div>

                        <Typography id="hello1" component="h2" variant="h5">
                          {post.title}
                  </Typography>
                  <Typography id="hello2" variant="subtitle1" color="textSecondary">
                          {post.code}
                   </Typography>
                 <Typography id="hello3" variant="subtitle1" paragraph>
                          {post.description}
                        </Typography> 
                        <Typography variant="subtitle1" color="primary">
                        </Typography>
                      </CardContent>
                    </div>
                    <Hidden xsDown>
                      <CardMedia
                        className={classes.cardMedia}
                         src="5.jpg"
                         title="Python code"
                      />
                    </Hidden>
                  </Card>
                {/*</CardActionArea> */}
              </Grid>
            ))}
          </Grid>
          {/* End sub featured posts */}
          <Grid container spacing={5} className={classes.mainGrid}>
            {/* Main content */}
            <Grid item xs={12} md={8}>
              <Typography variant="h6" gutterBottom>
                {/* Main Content */}
              </Typography>
              <Divider /> 
              {/* the real blog posts are below */}
		{posts.map(post => ( 
                 <Markdown className={classes.markdown}
                         key={post.substring(0, 6)}>
                      {post}
                </Markdown>
                   ))}
            </Grid>
            {/* End main content */}
            {/* Sidebar */}
            
            <Grid item xs={12} md={4}>
              <Paper elevation={0} className={classes.sidebarAboutBox}>
                <Typography variant="h6" gutterBottom>
                  Sponsored links:
<a href="https://www.ebay.com/sch/i.html?_from=R40&_trksid=p2380057.m570.l1313&_nkw=Learning-Scientific-Programming-With-Python&_sacat=0&mkcid=1&mkrid=711-53200-19255-0&siteid=0&campid=5338658580&toolid=20008&mkevt=1">Learning-Scientific-Programming-With-Python-by-Christian-Hill-English-Hardcover
               <img
                style={{ display: 'block' }}
                src="book_python.jpg" width="180" heigth="180"
                alt="background"
              /></a>
<a href="https://www.ebay.com/itm/A-Primer-on-Scientific-Programming-with-Python-Texts-in-Computational-Science-a/402653543383?epid=73941588&hash=item5dc0057bd7:g:gGYAAOSwruhZckz~&mkcid=1&mkrid=711-53200-19255-0&siteid=0&campid=5338658580&toolid=20008&mkevt=1">A Primer on Scientific Programming with Python (Texts in Computational Science and Engineering
 <img
                style={{ display: 'block' }}
                src="book_python_primer.jpg" width="180" heigth="180"
                alt="background"
              /></a>

                   </Typography>

{/* const myComponent = () =>  */}

                <Typography>
                 <Link href="https://github.com/oxy-gendotmobi?tab=gists"> Upload Snippet </Link>
                </Typography>
              </Paper>


              <Typography variant="h6" gutterBottom className={classes.sidebarSection}>
                 Areas 
              </Typography>
              {areas.map(area => (
                <Link display="block" variant="body1" href="#" key={area}>
                  {area}
                </Link>
              ))}
              <Typography variant="h6" gutterBottom className={classes.sidebarSection}>
                Social
              </Typography>
              {social.map(network => (
                <Link display="block" variant="body1" href={"https://"+network+".com"} key={network}>
                  {network}
                </Link>
              ))}
            </Grid>
            {/* End sidebar */}
          </Grid>
 </main>
      </Container>
      {/* Footer */}
      <footer className={classes.footer}>
        <Container maxWidth="lg">
          <Typography variant="h6" align="center" gutterBottom>
            {/* Footer */} 
          </Typography>
          <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          </Typography>
          <Copyright />
        </Container>
      </footer>

      {/* End footer */}
    </React.Fragment>
  );
}
