export default `
### requests
## requests Urllib3 Retry 
#### April 13, 2021
<code data-gist-id="db8ee5bc85bb4a7239620a344989bb28"></code>

Performing URL HTTP GET with retries in Python also requires:

$ pip install requests

`;
