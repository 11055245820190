export default `
### numpy
## Boolean indexing with Numpy
#### July 14, 2019 

<pre>import numpy as np

x = np.array([4, 6, 8, 1, 2, 6, 9])
y = x > 5
print(x[y])
gives [6 8 6 9]

Alternatively:
x = np.array([1, 2, 3, 4, 4, 35, 212, 5, 5, 6])
print(x[x < 5])
gives [1 2 3 4 4]
</pre>
`;
